<!-- revision 2021.03.11 slack - mono typeface, breadcrumb on mobile -->
<template lang="pug">
  article.page.min-h-screen.flex.flex-col.pt-45.lg_pt-0.bg-gray-100.pb-15
    template(v-if="doc")
      header.hidden.lg_block
        breadcrumb(:crumbs="[{ title: $prismic.asText(doc.title), path: $route.path }]")
      //- header.bg-white.h-45.border-t.border-b.lg_sticky.lg_top-0.lg_h-46.lg_border-t-0.flex.items-center
        breadcrumb-text(:back="true", @back="goBack", @click="goBack", :crumbs="[{ title: $prismic.asText(doc.title) }]")
        //- .lg_w-1x3.flex.items-center.lg_hidden.cursor-pointer(@click="goBack")
          svg-chevron-left.block.mr-12
          h1.inline.uppercase {{ $prismic.asText(doc.title) }}
      //- body
      slice-body(:slices="doc.body")
</template>

<script>
import svgChevronLeft from '@/components/SVG-ChevronLeft'
let lastRt
export default {
  name: 'Page',
  data () {
    return {
      doc: undefined
    }
  },
  methods: {
    async getDoc () {
      try {
        this.doc = (await this.$prismic.client.getByUID('page', this.$route.params.page)).data
      } catch (e) {
        console.error(e)
      }

      // const uid = this.$route.params.page
      // let doc = sessionStorage.getItem(uid)
      // if (!doc) {
      //   doc = (await this.$prismic.client.getByUID('page', this.$route.params.page)).data
      //   if (doc) sessionStorage.setItem(uid, doc)
      // }
      // this.doc = doc
    },
    goBack () {
      return lastRt?.name ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  beforeRouteEnter (to, from, next) {
    lastRt = from
    next()
  },
  created () {
    this.getDoc()
  },
  components: { svgChevronLeft }
}
</script>

<style>
</style>
